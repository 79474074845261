export class ExternalLinks {
  constructor() {
    // https://mtm.dev/external-links-new-tab
    const links = document.querySelectorAll( "a[href^='https://'], a[href^='http://']" )

    if ( links.length < 1 ) {
      return;
    }

    const host = window.location.hostname

    const isInternalLink = link => new URL( link ).hostname === host;

    links.forEach( link => {
      if ( isInternalLink( link ) ) {
        return
      }

      link.setAttribute( "target", "_blank" )
      link.setAttribute( "rel", "noopener nofollow noreferrer" )
    } )

  }

}
