export default class Filters {

    constructor(selector = '.filters a') {
        // Setup filtering
        this.filters = document.querySelectorAll(selector);
        if (this.filters.length) this.filters.forEach(this.setupLinks, this);
        this.setEvenOdd();
    }

    setupLinks(el) {
        var tag = el.getAttribute('data-tag');
        if (tag === null) return;
        if (tag !== '*' && !document.querySelectorAll('.video-link[data-tags*="'+tag+'"]') ) return;

        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            this.filterVideosByTag(tag);
        });
    }

    filterVideosByTag(tag) {
        var allTags = document.querySelectorAll('.filters a');
        allTags.forEach( function (item) {
            if(item.getAttribute('data-tag') === tag) {
                item.classList.add('is-active');
            } else {
                item.classList.remove('is-active');
            }
        });

        var allVideos = document.querySelectorAll('.video-link');
        if (tag === '*') {
            allVideos.forEach( function (video) {
                video.classList.remove('filter-out');
            });
        } else {
            allVideos.forEach( function (video) {
                if( video.getAttribute('data-tags') && video.getAttribute('data-tags').indexOf(tag) !== -1) {
                    video.classList.remove('filter-out');
                } else {
                    video.classList.add('filter-out');
                }
            });
        }

        this.setEvenOdd();

    }

    setEvenOdd() {
        var visibleVideos = document.querySelectorAll('.video-link:not(.filter-out)');
        for(var i = 0; i < visibleVideos.length; i++) {
            if(visibleVideos.length > 2) {
                visibleVideos[i].setAttribute('data-even', (i % 2));
            } else {
                visibleVideos[i].setAttribute('data-even', 0);
            }
        }
    }

}

