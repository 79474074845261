export default class VideoOverlay {

    constructor(selector = '[data-dialog]') {
        this.rootHtml = document.querySelector('html');
        this.toggleClass = 'modal-open';

        // Setup links
        const modal_links = document.querySelectorAll(selector);
        if(modal_links.length) modal_links.forEach(this.setupModal, this);
    }

    setupModal (el)
    {
        const dialog = document.getElementById(el.getAttribute('data-dialog'));
        if(!dialog) return;
        const closeBtn = dialog.querySelectorAll('.btn-close');

        const player = new Plyr('#player');

        el.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if(!dialog.open) {
                dialog.showModal();
                player.play();

                this.rootHtml.classList.add(this.toggleClass);
            }
        });

        if(closeBtn.length) {
            closeBtn.forEach( function(el) {
                el.addEventListener('click', (e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    if(dialog.open) {
                        player.pause();
                        dialog.close();
                        this.rootHtml.classList.remove(this.toggleClass);
                    }
                });
            }, this);
        }

    }

}
