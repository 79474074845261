import './bootstrap';
// import "core-js/stable";

import {ExternalLinks} from "./modules/_utils";
import VideoOverlay from "./modules/VideoOverlay";
import Filters from "./modules/Filters";

// on document ready
const domReady = () => {
    const rootHtml = document.querySelector('html');

    // Setup
    rootHtml.classList.remove('no-js');
    rootHtml.classList.add('js-ready');

    new ExternalLinks();
    new VideoOverlay();
    new Filters();

};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    domReady();
} else {
    document.addEventListener("DOMContentLoaded", domReady);
}
